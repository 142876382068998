import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
// import ArrowIcon from "../components/ArrowIcon";
// import LeftArrowIcon from "./leftArrowIcon";
import LeftIBMArrowIcon from "./LeftIBMArrowIcon";
// import RightArrowIcon from "./rightArrowIcon";
import RightIBMArrowIcon from "./RightIBMArrowIcon";
import OilAndEnergyLogo from "../images/maximo/oil-energy-logo.svg";

const IBMMaximoTestimonials = ({ testimonialData = null }) => {
    const clientTestimonials = useStaticQuery(graphql`
        {
            allContentfulTestimonials(
                filter: { testimonialType: { eq: false } }
                sort: { fields: updatedAt, order: DESC }
            ) {
                nodes {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                }
            }
        }
    `);

    if (!testimonialData) {
        testimonialData = clientTestimonials.allContentfulTestimonials.nodes;
    }

    return (
        <>
            <div className="pagination-navigation-ibm">
                <div className="pagination-arrows">
                    <span className="prev-openings">
                        <LeftIBMArrowIcon className="left-arrow-icon" />
                    </span>
                </div>
            </div>
            <div className="testimonial-wrapper">
                <Swiper
                    modules={[Pagination, Navigation]}
                    slidesPerView={1}
                    navigation={{
                        nextEl: ".next-openings",
                        prevEl: ".prev-openings",
                    }}
                    pagination={{
                        el: ".openings-pagination",
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                        1024: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {testimonialData.map((t, i) => (
                        <SwiperSlide key={i} className="testimonial-swiper">
                            <div className="testimonial-item">
                                <div className="testimonial-body">
                                    <h2 className="text-h2 text-clr-white text-fw-medium gap-05x">
                                        “Implementation of Maximo solution by EAM360"
                                    </h2>
                                    <p className="text-p3 text-clr-white text-fw-normal gap-4x">
                                        {t.testimonialStatement.testimonialStatement}
                                    </p>
                                </div>
                                <div className="testimonial-footer">
                                    <div className="testimonial-clientimage">
                                        <GatsbyImage
                                            image={t.profileImage.gatsbyImageData}
                                            alt={t.profileImage.title}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="testimonial-info">
                                        <div className="name">{t.personName}</div>
                                        <div className="designation">{t.personDesignation}</div>
                                    </div>
                                    <div className="oil-enegry-logo">
                                        <img src={OilAndEnergyLogo} alt="oil-logo" loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="pagination-navigation-ibm">
                <div className="pagination-arrows">
                    <span className="next-openings">
                        <RightIBMArrowIcon className="right-arrow-icon" />
                    </span>
                </div>
            </div>
        </>
    );
};

export default IBMMaximoTestimonials;
