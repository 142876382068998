import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const MaximoScale = ({ data = null }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [transitionDirection, setTransitionDirection] = useState("down");

    useEffect(() => {
        setTransitionDirection(activeItemIndex > 0 ? "down" : "down");
    }, [activeItemIndex]);

    useEffect(() => {
        if (data && data.length > 0) {
            setActiveItemIndex(0);
        }
    }, [data]);

    const handleItemClick = (index) => {
        setActiveItemIndex(index);
    };

    const activeItem = data ? data[activeItemIndex] : null;

    return (
        <div className="maximo-scale-grid">
            {/* List of buttons to click on */}
            <div className="maximo-scale-grid__list">
                {data?.map((item, index) => (
                    <div
                        key={item.id}
                        className={`list-item ${index === activeItemIndex ? "active" : ""}`}
                        onClick={() => handleItemClick(index)}
                    >
                        <div className="maximo-scale-grid__list-desc">
                            {item?.serviceIcon?.gatsbyImageData ? (
                                <GatsbyImage
                                    image={item.serviceIcon.gatsbyImageData}
                                    alt={item.title}
                                    className="maximo-solution-ogimage"
                                />
                            ) : (
                                <img
                                    className="maximo-solution-ogimage"
                                    src={item.serviceIcon.file.url || ""}
                                    alt={item.title}
                                />
                            )}
                            <h2 className="list-title">{item.title}</h2>
                        </div>
                        {index === activeItemIndex && (
                            <>
                                <p className="list-description">{item.description?.description}</p>
                                <div className="solution-features">
                                    {activeItem?.solutionLists?.map((feature, idx) => (
                                        <div
                                            key={`feature_${idx}`}
                                            className="solution-features-item text-p4 text-clr-primary text-fw-medium"
                                        >
                                            <ul>
                                                <li>{feature}</li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>

            {/* Content that changes based on the selected item */}
            <div
                className={`content ${transitionDirection}`}
                // style={{ backgroundColor: activeItem?.bgColor || "#fff" }}
            >
                {/* Display image */}
                <div className="maximo-campaign-solution__image">
                    {activeItem?.ogImage?.gatsbyImageData ? (
                        <GatsbyImage
                            image={activeItem.ogImage.gatsbyImageData}
                            alt={activeItem.title}
                            className="maximo-solution-ogimage"
                        />
                    ) : (
                        <img
                            className="maximo-solution-ogimage"
                            src={activeItem.ogImage?.file.url || ""}
                            alt={activeItem.title}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MaximoScale;
